import "core-js/stable";
import "regenerator-runtime/runtime";

require('../polyfills.js');
require('../src/styles/tracking/login.scss');

// These are the constraints used to validate the form
var constraints = {
  'customer[order_number]': {
    presence: {
      message: "^Field is required."
    }
  },
  'customer[email]': {
    presence: {
      message: "^Field is required"
    },
    email: {
      message: "^The email should be valid."
    }
  }
};

var form = document.getElementById('new_customer');
Validator.check(form, constraints);
